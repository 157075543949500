import Dexie from 'dexie';

const db = new Dexie('AppDataDB');

db.version(1).stores({
  messages: 'ID, task_id, admin_timestamp_read, family_id, file_metadata, message, referencedMessage, secret, timestamp, type, uploaded_file, user_id',
  pendingTasks: '++id, requestPayload'
});

// Upsert (insert or update) a message in IndexedDB.
export async function upsertOfflineMessage(message) {
  return db.messages.put(message);
}

// Retrieve messages for a specific task (using task_id).
export async function getOfflineMessagesByTask(taskId) {
  return db.messages.where('task_id').equals(taskId).toArray();
}

// Optionally, clear messages for a task.
export async function clearOfflineMessagesByTask(taskId) {
  return db.messages.where('task_id').equals(taskId).delete();
}

// Retrieve all messages that have the unsentMessage flag set to true.
export async function getUnsentMessages() {
    return db.messages.filter(message => message.unsentMessage === true).toArray();
  }

// Delete a specific offline message by its ID.
export async function deleteOfflineMessage(messageID) {
    return db.messages.delete(messageID);
  }
  

export default db;

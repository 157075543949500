import React from 'react';
import { AppContext } from '../AppContext';
import { loginStyles as styles } from './LoginStyles';
import { Typography, TextField, Button, CircularProgress, InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import manSuitImage from '../../images/man suit.jpg';
import redHairWomanGlassesImage from '../../images/red hair woman glasses.jpg';
import SignUp from './SignUp';

const baseURL = process.env.REACT_APP_API_BASE_URL;


class Login extends React.Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);
        this.state = {
            step: 0, // 0: Landing, 1: Enter Family ID, 2: Select Member, 3: Enter Password
            updatedFamilyID: '',
            familyID: null,
            members: [],
            memberID: null,
            memberEmail: '',
            memberName:'',
            password: '',
            newPassword: '',
            confirmPassword: '',
            noPassword: true,
            authErrorMessage: '',
            isLoading: false,
            showPassword: false,
            showNewPassword: false,
            showConfirmPassword: false,
            backgroundImage: manSuitImage,
            containerHeight: window.innerHeight,
            contentMaxHeight: window.innerHeight * 0.6,
        };
        this.headerRef = React.createRef();
        this.memberSelectionRef = React.createRef();
        this.formRef = React.createRef();
    }

    componentDidMount() {
        this.backgroundInterval = setInterval(this.toggleBackgroundImage, 10000);
        this.updateContainerHeight();
        this.calculateMaxHeights();
        window.addEventListener('resize', this.updateContainerHeight);
    }

    componentWillUnmount() {
        clearInterval(this.backgroundInterval);
        window.removeEventListener('resize', this.updateContainerHeight);
    }

    updateContainerHeight = () => {
        this.calculateMaxHeights();
        const height = window.innerHeight;
        this.setState({ containerHeight: height });
    };

    calculateMaxHeights = () => {
        const headerHeight = this.headerRef.current
            ? this.headerRef.current.getBoundingClientRect().height
            : 0;
    
        const footerHeight = 10; 
        const availableHeight = window.innerHeight - headerHeight - footerHeight;
        this.setState({ 
            contentMaxHeight:availableHeight, 
        });
    };

    toggleBackgroundImage = () => {
        this.setState((prevState) => ({
            backgroundImage: prevState.backgroundImage === manSuitImage 
                ? redHairWomanGlassesImage
                : manSuitImage,
        }));
    };

    handleInputChange = (event) => {
        this.setState({ updatedFamilyID: event.target.value });
    };

    handleClickShowPassword = () => {
        this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
    };

    handleClickShowNewPassword = () => {
        this.setState((prevState) => ({ showNewPassword: !prevState.showNewPassword }));
    };

    handleClickShowConfirmPassword = () => {
        this.setState((prevState) => ({ showConfirmPassword: !prevState.showConfirmPassword }));
    };

    handleSignUpClick = () => {    
        // this.context.navigate('/signUp');
        this.setState({ step: 4 });
    };

    handleGetHelpClick = () => {
        window.open('mailto:support@blckbx.co.uk', '_system');
    };

    handleSubmitFamilyID = async (event) => {
        event.preventDefault();
        const { updatedFamilyID } = this.state;
        if (updatedFamilyID.trim()) {
            this.setState({ isLoading: true });
            try {
                // Fetch members based on Family ID
                const familyID = updatedFamilyID.trim();
                const response = await fetch(`${baseURL}/utils/fetchFamilyData.php`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        familyID: familyID,
                        function: 'fetchMemberID',
                    }),
                });
                const members = await response.json();
                if (members.length > 0) {
                    this.setState({
                        familyID: familyID,
                        members: members,
                        step: 2,
                        isLoading: false,
                    });
                } else {
                    this.setState({
                        authErrorMessage: 'Invalid Family ID',
                        isLoading: false,
                    });
                }
            } catch (error) {
                console.error('Error fetching members:', error);
                this.setState({
                    authErrorMessage: 'An error occurred. Please try again.',
                    isLoading: false,
                });
            }
        }
    };

    handleMemberClick = async (memberID, selectedMember) => {
        this.setState({ isLoading: true });
        try {
            // Fetch member data
            const response = await fetch(`${baseURL}/utils/fetchMemberData.php`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ memberID }),
            });
            const data = await response.json();
            const memberEmail = data.Email;
            const memberName = selectedMember ? selectedMember : '';
            // Check if password exists
            const passwordExists = await this.checkForPassword(memberID);
            this.setState({
                memberID,
                memberEmail,
                memberName,
                noPassword: !passwordExists,
                step: 3,
                isLoading: false,
            });
        } catch (error) {
            console.error('Error fetching member data:', error);
            this.setState({
                authErrorMessage: 'An error occurred. Please try again.',
                isLoading: false,
            });
        }
    };

    checkForPassword = async (memberID) => {
        try {
            const response = await fetch(`${baseURL}/utils/clientLogin.php`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: `memberIDLookup=${encodeURIComponent(memberID)}`,
            });
            const data = await response.json();
            return data.exists;
        } catch (error) {
            console.error('Error checking for password:', error);
            return false;
        }
    };

    handleAuthInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

    validatePassword(password) {
        const regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$");
        return regex.test(password);
    }
    validateEmail(email) {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }

    handleAuth = async (event) => {
        event.preventDefault();
        const {
            familyID,
            memberID,
            memberEmail,
            password,
            newPassword,
            confirmPassword,
            noPassword,
        } = this.state;

        if (!memberEmail) {
            this.setState({ authErrorMessage: 'Please enter your email address.' });
            return;
        }

        if (!this.validateEmail(memberEmail)) {
            this.setState({ authErrorMessage: 'Please enter a valid email address.' });
            return;
        }

        if (noPassword) {
            if (newPassword !== confirmPassword) {
                this.setState({ authErrorMessage: 'Both passwords do not match.' });
                return;
            }

            if (!this.validatePassword(newPassword)) {
                this.setState({
                    authErrorMessage:
                        'Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character from the following: @$!%*?&.',
                });
                return;
            }
        }

        let payload = `username=${encodeURIComponent(memberEmail)}`;

        if (noPassword) {
            payload += `&newPassword=${encodeURIComponent(newPassword)}&familyID=${encodeURIComponent(
                familyID
            )}&memberID=${encodeURIComponent(memberID)}`;
        } else {
            payload += `&password=${encodeURIComponent(password)}`;
        }

        try {
            this.setState({ isLoading: true });
            const response = await fetch(`${baseURL}/utils/clientLogin.php`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: payload,
            });

            const data = await response.json();
            if (data.token) {
                localStorage.setItem('token', data.token);
                localStorage.setItem('familyID', familyID);
                localStorage.setItem('memberID', memberID);
                this.setState({ authErrorMessage: '', isLoading: false });
                // Notify the App component about successful authentication
                this.props.onLoginSuccess(familyID, memberID);
            } else {
                this.setState({ authErrorMessage: data.message, isLoading: false });
            }
        } catch (error) {
            console.error('Error in handleAuth:', error);
            this.setState({ authErrorMessage: 'An error occurred. Please try again.', isLoading: false });
        }
    };

    handleGoBack = () => {
        const { step } = this.state;
        if (step === 1) {
            this.setState({
                step: 0,
                updatedFamilyID: '',
                authErrorMessage: '',
            });

        } else if (step === 2) {
            this.setState({
                step: 1,
                familyID: null,
                members: [],
                updatedFamilyID: '',
                authErrorMessage: '',
            });
            localStorage.removeItem('familyID');
        } else if (step === 3) {
            this.setState({
                step: 2,
                memberID: null,
                memberEmail: '',
                password: '',
                newPassword: '',
                confirmPassword: '',
                noPassword: true,
                authErrorMessage: '',
            });
            localStorage.removeItem('memberID');
        } else if (step === 4) {
            this.setState({
                step: 0,
                authErrorMessage: '',
            });
        } else {
            this.props.onLogout();
        }
    };

    handleStartLogin = () => {
        this.setState({ step: 1 });
    };

    handleContactUsClick = () => {
        window.open('mailto:support@blckbx.co.uk', '_system');
    };

    renderLanding() {

        const {contentMaxHeight} =this.state
        const dynamicContainerStyle = {
            ...styles.landingContainer,
            maxHeight: contentMaxHeight,
            overflowY:'auto'
        };

        return (
            <div style={dynamicContainerStyle}>
                {/* optional overlay on the image  */}
                {/* <div style={styles.landingOverlay}></div> */}
                <div style={styles.landingContent}>

                <Typography
                    component="div"
                    style={styles.landingTitle}
                >
                    Work. Life. Balance.
                </Typography>
                <Typography
                        component="div"
                        style={styles.landingParagraph}
                    >
                    Welcome to your new personal assistant service, helping you to get time back to spend on the things you love.
                </Typography>

                {/* <Typography
                        component="div"
                        style={styles.landingQuestion}
                    >
                    Signed up and have your code?
                </Typography> */}


                <Button style={styles.secondaryButton} onClick={this.handleStartLogin}>
                    Get me set up
                </Button>

                {/* <Typography
                        component="div"
                        style={styles.landingQuestion}
                    >
                    Not yet signed up to the service?
                </Typography>
                <Button style={styles.secondaryButton} onClick={this.handleSignUpClick}>
                    How it works
                </Button>


                <Typography
                        component="div"
                        style={styles.landingFooter}
                    >
                    Need help?{' '}
                    <span
                    onClick={this.handleContactUsClick}
                    style={styles.underlineLink}
                >
                    Contact Us
                </span>
                </Typography> */}
            </div>
            </div>
        );
    }

    renderFamilyIDForm() {
        const { updatedFamilyID, authErrorMessage } = this.state;

        return (
            <div style={styles.formSection}>
                <Typography
                    component="div"
                    style={styles.familyIDTitle}
                >
                    Let’s get you started
                </Typography>
                <form style={styles.form} onSubmit={this.handleSubmitFamilyID}>
                    <TextField
                        sx={{
                            input: { color: 'black' },
                            '& .MuiOutlinedInput-root': {
                                backgroundColor: '#FFFFFF',
                                '& fieldset': {
                                    border: 'none',
                                },
                                '&.Mui-focused fieldset': {
                                    border: 'none',
                                },
                            },
                        }}
                        style={{ ...styles.textField }}
                        onChange={this.handleInputChange}
                        value={updatedFamilyID}
                        placeholder='Enter your family ID'
                        fullWidth
                        variant="outlined"
                    />

                    {/* <Typography
                        component="div"
                        style={styles.familyIDDescription}
                    >
                        You receive your unique code by email after you{' '}
                        <span
                            onClick={this.handleSignUpClick}
                            style={styles.underlineLink}
                        >
                            signed up
                        </span>{' '}
                        with us.
                    </Typography> */}



                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Button style={styles.submitButton} type="submit">
                            Login
                        </Button>
                    </div>
                </form>

                {authErrorMessage && (
                    <div style={styles.errorMessage}>
                        {authErrorMessage}
                    </div>
                )}
            </div>
        );
    }

    renderMemberSelection() {
        const { members, contentMaxHeight } = this.state;

        const dynamicContainerStyle = {
            ...styles.memberSelectionContainer,
            maxHeight: contentMaxHeight,
            overflowY:'auto'
        };

        return (
            <div style={dynamicContainerStyle}   ref={this.memberSelectionRef}>
                <Typography style={styles.memberSelectionHeader}>Welcome</Typography>
                {members.length === 1 ? (
                    <>
                        <div style={styles.memberSelectionDescription}>
                           {members[0].Name}
                        </div>

                        <div style={styles.memberSelectionButtonContainer}>
                            <button
                                key={members[0].ID}
                                style={styles.memberSelectionButton}
                                onClick={() => this.handleMemberClick(members[0].ID, members[0].Name )}
                            >
                                Enter
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                        <div style={styles.memberSelectionDescription}>
                       Please select your profile name
                        </div>
                        <div style={styles.memberSelectionButtonContainer}>
                            {members.map((member) => (
                                <button
                                    key={member.ID}
                                    style={styles.memberSelectionButton}
                                    onClick={() => this.handleMemberClick(member.ID,member.Name )}
                                >
                                    {member.Name}
                                </button>
                            ))}
                        </div>
                    </>
                )}
                <Typography style={styles.memberSelectionFooter}>
                    Your name not listed?{' '}
                    <span
                        onClick={this.handleGetHelpClick}
                        style={styles.underlineLink}
                    >
                        Get Help
                    </span>
                </Typography>

            </div>
        );
    }

    renderLoginForm() {
        const {
            noPassword,
            memberEmail,
            memberName,
            password,
            newPassword,
            confirmPassword,
            authErrorMessage,
            showPassword,
            showNewPassword,
            showConfirmPassword,
            contentMaxHeight
        } = this.state;

        const firstName = memberName ? memberName.split(' ')[0] : '';

        const dynamicFormStyle = {
            ...styles.formSection,
            maxHeight: contentMaxHeight,
            overflowY: 'auto',
        };

        return (
            <div style={dynamicFormStyle} ref={this.formRef}>
                   <Typography style={styles.loginFormHeader}>Hi, {firstName}</Typography>
                <Typography
                    component="div"
                    style={styles.loginFormSubheader}
                >
                    {noPassword ? 'Please create a New Password' : 'Please enter your Password'}
                </Typography>

                <Typography
                    component="div"
                    style={styles.loginFormSubheader}
                >
                    {noPassword ? 'Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character' : 'Please login with your registered email address, if you are having problems please contact us.'}
                </Typography>
                <form onSubmit={this.handleAuth} style={{ width: '100%' }}>
                    <TextField
                        name='memberEmail'
                        sx={{
                            input: { color: 'black' },
                            '& .MuiOutlinedInput-root': {
                                backgroundColor: '#FFFFFF',
                                '& fieldset': {
                                    border: 'none',
                                },
                                '&.Mui-focused fieldset': {
                                    border: 'none',
                                },
                            },
                        }}
                        style={styles.textField}
                        fullWidth
                        value={memberEmail}
                        onChange={this.handleAuthInputChange}
                        placeholder='Email Address'
                        autoComplete='email'
                        required
                        error={!!authErrorMessage}
                    />

                    {noPassword ? (
                        <>
                            <TextField
                                name='newPassword'
                                type={showNewPassword ? 'text' : 'password'}
                                sx={{
                                    input: { color: 'black' },
                                    '& .MuiOutlinedInput-root': {
                                        backgroundColor: '#FFFFFF',
                                        '& fieldset': {
                                            border: 'none',
                                        },
                                        '&.Mui-focused fieldset': {
                                            border: 'none',
                                        },
                                    },
                                }}
                                style={styles.textField}
                                fullWidth
                                value={newPassword}
                                onChange={this.handleAuthInputChange}
                                placeholder='New Password'
                                autoComplete='new-password'
                                required
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={this.handleClickShowNewPassword}
                                                edge="end"
                                            >
                                                {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                name='confirmPassword'
                                type={showConfirmPassword ? 'text' : 'password'}
                                sx={{
                                    input: { color: 'black' },
                                    '& .MuiOutlinedInput-root': {
                                        backgroundColor: '#FFFFFF',
                                        '& fieldset': {
                                            border: 'none',
                                        },
                                        '&.Mui-focused fieldset': {
                                            border: 'none',
                                        },
                                    },
                                }}
                                style={styles.textField}
                                fullWidth
                                value={confirmPassword}
                                onChange={this.handleAuthInputChange}
                                placeholder='Confirm New Password'
                                autoComplete='new-password'
                                required
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle confirm password visibility"
                                                onClick={this.handleClickShowConfirmPassword}
                                                edge="end"
                                            >
                                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </>
                    ) : (
                        <TextField
                            name='password'
                            type={showPassword ? 'text' : 'password'}
                            sx={{
                                input: { color: 'black' },
                                '& .MuiOutlinedInput-root': {
                                    backgroundColor: '#FFFFFF',
                                    '& fieldset': {
                                        border: 'none',
                                    },
                                    '&.Mui-focused fieldset': {
                                        border: 'none',
                                    },
                                },
                            }}
                            style={styles.textField}
                            fullWidth
                            value={password}
                            onChange={this.handleAuthInputChange}
                            placeholder='Password'
                            required
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={this.handleClickShowPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}

                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Button
                            type='submit'
                            style={{ ...styles.submitButton, width: '100%', maxWidth: '300px' }} 
                        >
                            {noPassword ? 'Set New Password' : 'Login'}
                        </Button>
                    </div>
                    {!noPassword && (
                         <Typography
                         component="div"
                         style={styles.loginFormForgotPassword}
                     >
                         Forgotten password?{' '}
                    <span
                        onClick={null}
                    >
                        Contact your assistant directly
                    </span>
                     </Typography>
                    )}
                </form>

                {authErrorMessage && (
                    <div style={styles.errorMessage}>
                        {authErrorMessage}
                    </div>
                )}
            </div>
        );
    }

    renderSignUpForm() {
        return (
           <SignUp />
        );
    }
    

    render() {
        const { step, isLoading, backgroundImage , containerHeight} = this.state;

        const containerStyle = {
            ...styles.container,
            paddingTop: 'env(safe-area-inset-top)',
            paddingBottom: 'calc(env(safe-area-inset-bottom, 0px) + 20px)',
            height: containerHeight,
            justifyContent: step === 0 ? 'space-between' : 'flex-start',
        };
    
        const mainContentStyle = {
            ...styles.mainContentWrapper,
            justifyContent: step === 0 ? 'flex-end' : 'center',
        };
    
        const dynamicBackgroundStyle = {
            ...styles.background,
            backgroundImage: `url('${backgroundImage}')`,
        };

        return (
            <div style={containerStyle}>
              {step === 0 && <div style={dynamicBackgroundStyle}></div>}
               {/* Header Section */}
               <div style={styles.header} ref={this.headerRef}>
                    {/* Left Header */}
                    <div style={styles.leftHeader}>
                        {step > 0 && !isLoading && (
                            <IconButton
                                onClick={this.handleGoBack}
                                style={styles.goBackButton}
                                aria-label="Go Back"
                            >
                                <ChevronLeftIcon />
                            </IconButton>
                        )}
                    </div>

                    {/* Center Header */}
                    <div style={styles.centerHeader}>
                        <div style={styles.logoContainer}>
                            <img src={step === 0 ? 'BlckBx PNG inverted.png' : "BlckBx PNG.png"} alt="Logo" style={styles.logo} />
                        </div>
                    </div>

                    {/* Right Header */}
                    <div style={styles.rightHeader}>
                        {/* Empty div to balance the header */}
                    </div>
                </div>

                {isLoading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                        <CircularProgress style={{ color: '#1C1D1F' }} />
                    </div>
                ) : (
                    <div style={mainContentStyle}>
                        {step === 0 && this.renderLanding()}
                        {step === 1 && this.renderFamilyIDForm()}
                        {step === 2 && this.renderMemberSelection()}
                        {step === 3 && this.renderLoginForm()}
                        {step === 4 && this.renderSignUpForm()}
                    </div>
                )}
            </div>
        );
    }
}

export default Login;

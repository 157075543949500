import React, { useState } from 'react';
import { Grid, Button, Dialog, Tabs, Tab, Typography, TextField, Box, InputAdornment, Chip, IconButton } from '@mui/material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import TaskCard from './TaskCard';
import { AppContext } from './AppContext.js';
import SearchIcon from '@mui/icons-material/Search';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
// import FilterListIcon from '@mui/icons-material/FilterList';
import AddTaskIcon from '@mui/icons-material/AddTask';
// import PaymentDetailsSection from './PaymentDetailsSection.js';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CategoryAlertDialog from './CategoryAlertDialog.js';
//import CloudOffIcon from '@mui/icons-material/CloudOff';

import AutorenewIcon from '@mui/icons-material/Autorenew';
import { styled } from '@mui/material/styles';

const SpinIcon = styled(AutorenewIcon)({
  animation: 'spin 1s linear 2', // spins 2 times over 2 seconds
  '@keyframes spin': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
  },
});

const priorityTooltips = {
    'In progress': 'Up to 3 tasks in progress',
    'Waiting on you': 'Tasks awaiting your input',
    'Up next': 'Tasks that are next in line',
    'Paused': 'Tasks that have been temporarily halted.',
    ' ':'Tasks would be worked on order listed'
};

const styles = {
    priorityText: {
        display: 'flex',
        justifyContent: 'Left',
        alignItems: 'center',
        color: '#000000',
        paddingBottom: '4px',
        paddingTop: '12px',
        fontSize: 16,
        fontWeight: 400,
    },
    iconButtonStyles: {
        margin: '0px 5px',
        backgroundColor: '#FFFFFF',
        width: '48px',
        height: '48px',
        borderRadius: '15px',
        minWidth: '48px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    },
    addButtonStyles: {
        margin: '0px 5px',
        background: 'black',
        width: '48px',
        height: '48px',
        borderRadius: '15px',
        minWidth: '48px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        marginRight: 0,
    },
    searchBox: {
        background: '#FFFFFF',
        borderRadius: '15px',
        overflow: 'hidden',
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: 'normal',
        fontSize: '1rem',
        margin: '16px 0px',
        width: '100%',
    },
    containerStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    infoIconStyle: {
        marginLeft: 4,
        color: '#1C1D1F',
        cursor: 'pointer',
    },
    tooltipSx: {
        '& .MuiTooltip-tooltip': {
            padding: '0px',
        },
    },
    modalTextStyle: {
        textAlign: 'center',
        color: '#1C1D1F',
        fontSize: 16,
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: '500',
        wordWrap: 'break-word',
        width: '100%',
    },
    buttonTextStyle: {
        textAlign: 'center',
        color: 'white',
        fontSize: 16,
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: '500',
        wordWrap: 'break-word',
        width: '100%',
    },
    buttonStyle: {
        alignSelf: 'stretch',
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 10,
        paddingBottom: 10,
        background: '#1C1D1F',
        borderRadius: 12,
        overflow: 'hidden',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 8,
        display: 'inline-flex',
        cursor: 'pointer',
    },
    dialogPaperStyle: {
        backgroundColor: '#E8E5E0',
        borderRadius: '8px',
        padding: '20px 15px',
    },
    taskListContainer: {
        margin: '16px',
    },
    offlineBanner: {
        marginTop:'10px',
        width: "100%",
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        wordWrap: 'break-word',
        backgroundColor: '#555556',
        padding:'14px 20px',
        marginBottom:'10px'
      },
    
      offlineBannerText:{
        paddingRight:'16px',
        fontSize: '14px',
        fontStyle:'normal',
        fontFamily: "'Open Sans', sans-serif",
        color: '#F5F3F0',
    
      },
    noTasksContainer: {
        textAlign: 'center',
        marginTop: 20,
        padding: '0px 16px',
    },
    noTasksTypography: {
        width: '100%',
        fontSize: '14px',
        color: '#000000',
        marginTop: '10px',
        fontFamily: "'Open Sans', sans-serif",
        background: '#F5F3F0',
        padding: '14px 16px',
        borderRadius: '8px',
    },
    dialogParagraph: {
        textAlign: 'center',
        color: '#1C1D1F',
        fontSize: 16,
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: '500',
        wordWrap: 'break-word',
        width: '100%',
        paddingTop: '5px',
        paddingBottom: '15px',
    },
    dialogButtonContainer: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    cancelButton: {
        alignSelf: 'stretch',
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 10,
        paddingBottom: 10,
        backgroundColor: 'grey',
        borderRadius: 12,
        overflow: 'hidden',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 8,
        display: 'inline-flex',
        cursor: 'pointer',
        marginRight: '10px',
        flex: 1,
    },
    okButton: {
        alignSelf: 'stretch',
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 10,
        paddingBottom: 10,
        background: '#1C1D1F',
        borderRadius: 12,
        overflow: 'hidden',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 8,
        display: 'inline-flex',
        cursor: 'pointer',
        flex: 1,
    },
    disabledButtonTextStyle: {
        textAlign: 'center',
        color: 'white',
        fontSize: 16,
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: '500',
        wordWrap: 'break-word',
        width: '100%',
        cursor: 'not-allowed',
        pointerEvents: 'none',
        opacity: '50%',
    },
    taskItemStyle: {
        border: null,
    },
    tasksTitle: {
        fontSize: '32px',
        fontWeight: '400',
        fontFamily: "'EB Garamond', serif",
        flexGrow: 1,
    },
    searchIconStyle: {
        color: '#1C1D1F',
        cursor: 'pointer',
    },
    checkCircleIconSx: {
        color: '#5BBEA6',
        marginRight: '8px',
        fontSize: '24px',
    },
    successMessageBox: {
        backgroundColor: '#DBF2F0',
        borderRadius: '8px',
        marginBottom: '14px',
        padding: '16px',
        boxShadow: 'none',
        border: 'none',
    },
    successMessageInnerBox: {
        display: 'flex',
        alignItems: 'center',
    },
    successMessageIcon: {
        color: '#5BBEA6',
        marginRight: '8px',
        fontSize: '24px',
    },
    successMessageText: {
        color: '#1C1D1F',
        fontSize: '14px',
        fontFamily: "'Open Sans', sans-serif",
    },
    tabStyles: {
        textTransform: 'none',
        fontSize: '16px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
        fontFamily: "'Open Sans', sans-serif",
        '&.Mui-selected': {
            fontWeight: 600,
            borderBottom: '1px solid black',
        },
        '&:not(.Mui-selected)': {
            color: 'rgba(0, 0, 0, 0.40)',
        },
    },
    gridContainer: {
        padding: '15px 12px 2px 16px',
        marginTop: '5px',
    },
    gridItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    textFieldSx: {
        input: { color: 'black', fontFamily: "'Open Sans', sans-serif" },
        '& .MuiOutlinedInput-root': {
            backgroundColor: '#FFFFFF',
            '& fieldset': {
                border: 'none',
            },
            '&.Mui-focused fieldset': {
                border: 'none',
            },
        },
    },
    iconColor: {
        color: '#1C1D1F',
    },
    taskItemContainer: {
        margin: '16px',
    },
};

const PriorityLabel = ({ label }) => {
    const [open, setOpen] = useState(false);

    const handleIconClick = (event) => {
        event.stopPropagation();
        setOpen((prev) => !prev);
    };

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    const containerStyle = {
        ...styles.containerStyle,
        paddingRight: label === 'Paused' ? '16px' : 0,
    };

    return (
        <div style={containerStyle}>
            <span>{label}</span>
            <Tooltip
                title={priorityTooltips[label] || 'No additional information.'}
                open={open}
                onClose={handleTooltipClose}
                onOpen={handleTooltipOpen}
                interactive
                disableHoverListener={false} // Enable hover
                disableFocusListener={false} // Enable focus
                placement="left"
                PopperProps={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, -20],
                            },
                        },
                    ],
                }}
                sx={styles.tooltipSx}
            >
                <InfoOutlinedIcon
                    fontSize="small"
                    style={styles.infoIconStyle}
                    onClick={handleIconClick}
                />
            </Tooltip>
        </div>
    );
};

class TaskPage extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            swapDialogOpen: false,
            selectedTask: null,
            taskToBePinned: null,
            replacementButtonVisible: false,
            searchQuery: '',
            showSearchBox: false,
            selectedTab: 0, // 0 for Active, 1 for Pending, 2 for Completed
            filteredCategory: 'All Categories',
            showContent: {},
            isCategoryDialogOpen: false,
            selectedCategory: 'All Categories',
        };

        this.pinnedListRef = React.createRef();
        this.handleTaskClick = this.handleTaskClick.bind(this);
        this.handleSwapDialogClose = this.handleSwapDialogClose.bind(this);
        this.handleTaskReplacement = this.handleTaskReplacement.bind(this);
        this.handleTaskSelection = this.handleTaskSelection.bind(this);
        this.handleChildStateChange = this.handleChildStateChange.bind(this);
        this.setMultipleRefs = this.setMultipleRefs.bind(this);
        this.getItemStyle = this.getItemStyle.bind(this);
        this.renderTaskListItems = this.renderTaskListItems.bind(this);
        this.renderTaskListSelection = this.renderTaskListSelection.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.toggleContent = this.toggleContent.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.toggleSearchBox = this.toggleSearchBox.bind(this);
    }

    handleTaskClick = (task) => {
        if (!this.state.swapDialogOpen) {
            this.context.setChatTask(task);
            this.context.clearTaskBadges(task.id).then(() => {
                this.context.navigate('/chat');
            });
        }
    };

    // for when we do task Overview
    // handleTaskClick(task) {
    //     if (!this.state.swapDialogOpen) {
    //         this.context.setChatTask(task)
    //         this.context.navigate('/taskOverview');
            
    //     }
    // }

    openCategoryDialog = () => {
        this.setState({ isCategoryDialogOpen: true });
    };

    closeCategoryDialog = () => {
        this.setState({ isCategoryDialogOpen: false });
    };


    handleCategoryChange = (category) => {
        this.setState({ selectedCategory: category, isCategoryDialogOpen: false }, () => {});
    };

    toggleContent(itemId) {
        this.setState((prevState) => ({
            showContent: {
                ...prevState.showContent,
                [itemId]: !prevState.showContent[itemId],
            },
        }));
    }

    handleSearchChange(event) {
        const value = event.target.value;
        this.setState({
            searchQuery: value,
        });
    }

    toggleSearchBox() {
        this.setState((prevState) => ({
            showSearchBox: !prevState.showSearchBox,
            searchQuery: '',
        }));
    }

    handleSwapDialogClose() {
        this.setState({
            swapDialogOpen: false,
            selectedTask: null,
            taskToBePinned: null,
            replacementButtonVisible: false,
        });
    }

    handleTaskReplacement() {
        this.context.handleTogglePin(this.state.selectedTask.id, false);
        this.context.handleTogglePin(this.state.taskToBePinned, true);
        this.setState({
            swapDialogOpen: false,
            selectedTask: null,
            taskToBePinned: null,
            replacementButtonVisible: false,
        });
    }

    handleTaskSelection(task, e) {
        this.setState({
            selectedTask: task,
            replacementButtonVisible: true,
        });

        const taskItems = document.querySelectorAll('.task-item');
        taskItems.forEach((element) => {
            element.classList.remove('active');
        });

        const clickedElement = e.currentTarget;
        if (clickedElement.classList.contains('active')) {
            clickedElement.classList.remove('active');
        } else {
            clickedElement.classList.add('active');
        }
    }

    handleChildStateChange(isErrorDialogOpen, taskIdToBePinned) {
        this.setState({
            swapDialogOpen: isErrorDialogOpen,
            taskToBePinned: taskIdToBePinned,
        });

        if (this.pinnedListRef.current) {
            const rect = this.pinnedListRef.current.getBoundingClientRect();
            this.setState({
                dialogPosition: {
                    top: rect.top + window.scrollY,
                    left: rect.left + window.scrollX,
                    width: rect.width,
                    height: rect.height,
                },
            });
        }
    }

    setMultipleRefs(provided) {
        return (element) => {
            provided.innerRef(element);
            this.pinnedListRef.current = element;
        };
    }

    getItemStyle(draggableStyle) {
        return {
            ...draggableStyle,
        };
    }

    calculatePinnedCount(items) {
        return items.filter((item) => item.isPinned).length;
    }

    handleTabChange(event, newValue) {
        this.setState({ selectedTab: newValue, searchQuery: '', showSearchBox: false, selectedCategory: 'All Categories' });
    }

    renderTaskListItems(taskItems, priorityLabel, tasksPinnedCount) {

        const priorityTextStyle = {
            ...styles.priorityText,
            paddingTop:
                priorityLabel === 'In Progress' ||
                (priorityLabel === 'Up Next' && this.state.selectedTab === 1)
                    ? 0
                    : styles.priorityText.paddingTop,
        };

        return (
            <div className={`${priorityLabel}`} style={styles.taskListContainer}>
                <div style={priorityTextStyle}>
                    <PriorityLabel label={priorityLabel} />
                </div>
                {taskItems.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id} index={index}>
                        {(provided) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={this.getItemStyle(provided.draggableProps.style)}
                                onClick={() => this.handleTaskClick(item)}
                            >
                                <TaskCard
                                    index={index}
                                    chatTask={item}
                                    unreadMessageCount={this.context.unreadMessageCount[item.id]}
                                    showControls={true}
                                    isItemPinned={item.isPinned}
                                    handlePin={(taskId) => this.context.handleTogglePin(taskId, true)}
                                    handleRemovePin={(taskId) => this.context.handleTogglePin(taskId, false)}
                                    maxPinnedItems={this.context.maxActiveTasks}
                                    currentPinnedCount={tasksPinnedCount}
                                    onDeleteTask={this.context.handleDeleteTask}
                                    isItemHold={item.isHold}
                                    handleHold={(taskId) => this.context.handleToggleHold(taskId, 1)}
                                    handleUnhold={(taskId) => this.context.handleToggleHold(taskId, 0)}
                                    onChildStateChange={this.handleChildStateChange}
                                />
                            </div>
                        )}
                    </Draggable>
                ))}
            </div>
        );
    }

    renderTaskListSelection(taskItems, priorityLabel, tasksPinnedCount) {
        return (
            <div className={priorityLabel}>
                <div style={{ ...styles.priorityText }}>
                    {priorityLabel}
                </div>
                {taskItems.map((item, index) => {
                    const itemStyle = {
                        ...this.getItemStyle(),
                        ...styles.taskItemStyle,
                        opacity: item.fields['Task - Status Text'][0] === 'Info Needed' ? 0.5 : 1,
                    };
                    return (
                        <div
                            key={item.id}
                            style={itemStyle}
                            className={`task-item ${item.fields['Task - Status Text'][0] === 'Info Needed' ? 'disableHoverFocus' : ''}`}
                            onClick={item.fields['Task - Status Text'][0] === 'Info Needed' ? undefined : (e) => this.handleTaskSelection(item, e)}
                            tabIndex="0"
                        >
                            <TaskCard
                                index={index}
                                chatTask={item}
                                unreadMessageCount={this.context.unreadMessageCount[item.id]}
                                showControls={false}
                                isChat={true}
                                isItemPinned={item.isPinned}
                                onClickTask={() => this.handleTaskClick(item)}
                                handlePin={(taskId) => this.context.handleTogglePin(taskId, true)}
                                handleRemovePin={(taskId) => this.context.handleTogglePin(taskId, false)}
                                maxPinnedItems={this.context.maxActiveTasks}
                                currentPinnedCount={tasksPinnedCount}
                                onDeleteTask={this.context.handleDeleteTask}
                                isItemHold={item.isHold}
                                handleHold={(taskId) => this.context.handleToggleHold(taskId, 1)}
                                handleUnhold={(taskId) => this.context.handleToggleHold(taskId, 0)}
                                onChildStateChange={this.handleChildStateChange}
                            />
                        </div>
                    );
                })}
            </div>
        );
    }

    renderAllActiveTasks(items){

        const holdItems = items.filter((item) => item.isHold);
        
        const activeItems = items.filter((item) => !item.isHold);
        const tasksPinnedCount = this.calculatePinnedCount(items);
        const pendingTasks = this.context.queuedTasks

        return (
            <div>
                {items.length === 0 && (
                    <div style={styles.noTasksContainer}>
                        <Typography style={styles.noTasksTypography}> No tasks found</Typography>
                    </div>
                )}

                {pendingTasks && pendingTasks.length > 0 && (
                    <Box
                        sx={{
                            mt: 'auto',
                            mb: 1,
                            padding: '16px'
                        }}
                    >
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                            Queued Tasks
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#555', mb: 1 }}>
                            Hang tight! Tasks will automatically sync once you're online.
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '14px',
                            }}
                        >
                            {pendingTasks.map((task, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        padding: '6px 10px',
                                        backgroundColor: '#fff',
                                        borderRadius: '8px',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                marginLeft: '4px',
                                                fontSize: '14px',
                                                fontWeight: '600',
                                                letterSpacing: '0.2px',
                                                fontFamily: "'Open Sans', sans-serif",
                                                color: '#1C1D1F',
                                            }}
                                        >
                                            {task.requestPayload.taskText}
                                        </Typography>
                                    </Box>
                                    <IconButton
                                        color="primary"
                                    >
                                        <SpinIcon sx={{ color: 'black' }} />
                                    </IconButton>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                )}

                <div className={`main-cards`}>
                    <DragDropContext onDragEnd={this.context.onDragEnd}>
                        <Droppable droppableId="NormalPriority">
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                                         
                                    {activeItems.map((item, index) => (
                                        
                                        <Draggable key={item.id} draggableId={item.id} index={index}>
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                
                                                    style={{...this.getItemStyle(provided.draggableProps.style),...styles.taskListContainer}}
                                                    onClick={() => this.handleTaskClick(item)}
                                                >
                                                    <TaskCard
                                                        index={index}
                                                        chatTask={item}
                                                        unreadMessageCount={this.context.unreadMessageCount[item.id]}
                                                        showControls={true}
                                                        isItemPinned={item.isPinned}
                                                        handlePin={(taskId) => this.context.handleTogglePin(taskId, true)}
                                                        handleRemovePin={(taskId) => this.context.handleTogglePin(taskId, false)}
                                                        maxPinnedItems={this.context.maxActiveTasks}
                                                        currentPinnedCount={tasksPinnedCount}
                                                        onDeleteTask={this.context.handleDeleteTask}
                                                        isItemHold={item.isHold}
                                                        handleHold={(taskId) => this.context.handleToggleHold(taskId, 1)}
                                                        handleUnhold={(taskId) => this.context.handleToggleHold(taskId, 0)}
                                                        onChildStateChange={this.handleChildStateChange}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>

                    {holdItems.length > 0 && (
                        <div>
                            <Typography style={{ ...styles.priorityText, marginLeft: '16px' }}>
                                <PriorityLabel label="Paused" />
                            </Typography>
                            {holdItems.map((item, index) => (
                                <div
                                    key={item.id}
                                    style={styles.taskListContainer}
                                    onClick={() => this.handleTaskClick(item)}
                                >
                                    <TaskCard
                                        index={index}
                                        chatTask={item}
                                        unreadMessageCount={this.context.unreadMessageCount[item.id]}
                                        showControls={true}
                                        isItemPinned={item.isPinned}
                                        handlePin={(taskId) => this.context.handleTogglePin(taskId, true)}
                                        handleRemovePin={(taskId) => this.context.handleTogglePin(taskId, false)}
                                        maxPinnedItems={this.context.maxActiveTasks}
                                        currentPinnedCount={tasksPinnedCount}
                                        onDeleteTask={this.context.handleDeleteTask}
                                        isItemHold={item.isHold}
                                        handleHold={(taskId) => this.context.handleToggleHold(taskId, 1)}
                                        handleUnhold={(taskId) => this.context.handleToggleHold(taskId, 0)}
                                        onChildStateChange={this.handleChildStateChange}
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        );

    }

    renderUserView(items) {
        items = items.filter((item) => !item.isHold);

        const pinnedItems = items.filter((item) => item.isPinned && item.fields['Task - Status Text'][0] !== 'Info Needed');
        const unpinnedItems = items.filter((item) => !item.isPinned && item.fields['Task - Status Text'][0] !== 'Info Needed');

        const infoNeededItems = items.filter((item) => {
            return item.fields && (item.fields['Task - Status Text'][0] === 'Info Needed' || item.fields['Task - Status Text'][0] === 'Info Needed from Client' || item.fields['Task - Status Text'][0] === 'Info Needed from Supplier');
        });

        const tasksPinnedCount = this.calculatePinnedCount(items);

        return (
            <div>

                {items.length === 0 && (
                    <div style={styles.noTasksContainer}>
                        <Typography style={styles.noTasksTypography}>No Tasks found.</Typography>
                    </div>
                )}
                <div className={`main-cards`}>
                    <DragDropContext onDragEnd={this.context.onDragEnd}>
                        <Droppable droppableId="HighPriority">
                            {(provided) => (
                                <React.Fragment>
                                    <>
                                        <div {...provided.droppableProps} ref={this.setMultipleRefs(provided)}>
                                            {pinnedItems.length > 0 && this.renderTaskListItems(pinnedItems, 'In progress', tasksPinnedCount)}
                                            {provided.placeholder}
                                        </div>
                                        {this.state.swapDialogOpen ? (
                                            <Dialog
                                                open={this.state.swapDialogOpen}
                                                onClose={this.handleSwapDialogClose}
                                                PaperProps={{
                                                    style: styles.dialogPaperStyle,
                                                }}
                                            >
                                                <div style={styles.modalTextStyle}>
                                                    <b>Choose Task To Replace</b>
                                                </div>
                                                <div style={styles.dialogParagraph}>
                                                    Only {this.context.maxActiveTasks} {this.context.maxActiveTasks === 1 ? 'task' : 'tasks'} can
                                                    be worked on at one time. Select a task to replace
                                                </div>
                                                {pinnedItems.length > 0 && this.renderTaskListSelection(pinnedItems, 'In progress', tasksPinnedCount)}
                                                {provided.placeholder}
                                                <div style={styles.dialogButtonContainer}>
                                                    <div style={styles.cancelButton}>
                                                        <div onClick={this.handleSwapDialogClose} style={styles.buttonTextStyle}>
                                                            Cancel
                                                        </div>
                                                    </div>
                                                    <div style={styles.okButton}>
                                                        <div
                                                            onClick={this.state.replacementButtonVisible ? this.handleTaskReplacement : null}
                                                            style={
                                                                this.state.replacementButtonVisible
                                                                    ? styles.buttonTextStyle
                                                                    : styles.disabledButtonTextStyle
                                                            }
                                                        >
                                                            OK
                                                        </div>
                                                    </div>
                                                </div>
                                            </Dialog>
                                        ) : null}
                                    </>
                                </React.Fragment>
                            )}
                        </Droppable>

                        <Droppable droppableId="InfoPriority">
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {infoNeededItems.length > 0 && this.renderTaskListItems(infoNeededItems, 'Waiting on you', tasksPinnedCount)}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                        <Droppable droppableId="NormalPriority">
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {unpinnedItems.length > 0 && this.renderTaskListItems(unpinnedItems, 'Up next', tasksPinnedCount)}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </div>
        );
    }

    renderPendingTasks() {
        const { searchQuery, selectedCategory } = this.state;
        const filteredItems = this.context.items
            .filter((item) => selectedCategory === 'All Categories' || item.fields['Main Category'] === selectedCategory)
            .filter((item) => !searchQuery || item.fields['Title'].toLowerCase().includes(searchQuery.toLowerCase()));

        const holdItems = filteredItems.filter((item) => item.isHold);

        const unpinnedItems = filteredItems.filter(
            (item) => !item.isHold && !item.isPinned && item.fields['Task - Status Text'][0] !== 'Info Needed'
        );

        const tasksPinnedCount = this.calculatePinnedCount(filteredItems);

        return (
            <div>

                {filteredItems.length === 0 && (
                    <div style={styles.noTasksContainer}>
                        <Typography style={styles.noTasksTypography}> No Pending tasks found</Typography>
                    </div>
                )}

                <div className={`main-cards`}>
                    <DragDropContext onDragEnd={this.context.onDragEnd}>
                        <Droppable droppableId="NormalPriority">
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {unpinnedItems.length > 0 && this.renderTaskListItems(unpinnedItems, 'Up next', tasksPinnedCount)}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>

                    {holdItems.length > 0 && (
                        <div>
                            <Typography style={{ ...styles.priorityText, marginLeft: '16px' }}>
                                <PriorityLabel label="Paused" />
                            </Typography>
                            {holdItems.map((item, index) => (
                                <div
                                    key={item.id}
                                    style={styles.taskListContainer}
                                    onClick={() => this.handleTaskClick(item)}
                                >
                                    <TaskCard
                                        index={index}
                                        chatTask={item}
                                        unreadMessageCount={this.context.unreadMessageCount[item.id]}
                                        showControls={true}
                                        isItemPinned={item.isPinned}
                                        handlePin={(taskId) => this.context.handleTogglePin(taskId, true)}
                                        handleRemovePin={(taskId) => this.context.handleTogglePin(taskId, false)}
                                        maxPinnedItems={this.context.maxActiveTasks}
                                        currentPinnedCount={tasksPinnedCount}
                                        onDeleteTask={this.context.handleDeleteTask}
                                        isItemHold={item.isHold}
                                        handleHold={(taskId) => this.context.handleToggleHold(taskId, 1)}
                                        handleUnhold={(taskId) => this.context.handleToggleHold(taskId, 0)}
                                        onChildStateChange={this.handleChildStateChange}
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        );
    }
    

    renderCompletedTasks() {
        const { selectedCategory, searchQuery } = this.state;
        const filteredItems = this.context.doneItems
            .filter((item) => selectedCategory === 'All Categories' || item.fields['Main Category'] === selectedCategory)
            .filter((item) => !searchQuery || item.fields['Title'].toLowerCase().includes(searchQuery.toLowerCase()));

        return (
            <div>
                {filteredItems.length === 0 && (
                    <div style={styles.noTasksContainer}>
                        <Typography style={styles.noTasksTypography}> No completed tasks found.</Typography>
                    </div>
                )}

                {filteredItems.map((item) => (
                    <div key={item.id} style={styles.taskItemContainer} onClick={() => this.handleTaskClick(item)}>
                        <TaskCard
                            showControls={true}
                            index={0}
                            chatTask={item}
                            unreadMessageCount={this.context.unreadMessageCount[item.id]}
                        />

                        {/* Moved to TaskCard */}
                        {/* {item.fields['receipt'] && (
                            <PaymentDetailsSection
                                item={item}
                                receipt={item.fields['receipt']}
                                isVisible={this.state.showContent[item.id]}
                                onToggle={() => this.toggleContent(item.id)}
                                itemId={item.id}
                            />
                        )} */}
                    </div>
                ))}
            </div>
        );
    }

    render() {
        const { searchQuery, selectedTab, showSearchBox, isCategoryDialogOpen, selectedCategory } = this.state;
        const filteredTasks = this.context.items
            .filter((item) => !searchQuery || item.fields['Title'].toLowerCase().includes(searchQuery.toLowerCase()))
            .filter((item) => selectedCategory === 'All Categories' || item.fields['Main Category'] === selectedCategory);

        return (
            <div>
  

                <CategoryAlertDialog
                    open={isCategoryDialogOpen}
                    onClose={this.closeCategoryDialog}
                    onCategoryChange={this.handleCategoryChange}
                    selectedCategory={selectedCategory}
                />

                <Tabs
                    value={this.state.selectedTab}
                    onChange={this.handleTabChange}
                    textColor="black"
                    aria-label="task tabs"
                    centered
                    sx={{
                        '& .MuiTabs-indicator': {
                            display: 'none',
                        },
                    }}
                >
                    <Tab label="To Do" sx={styles.tabStyles} />
                    {/* Pending tab if we want this in the future */}
                    {/* <Tab label="Pending" sx={styles.tabStyles} /> */}
                    <Tab label="Completed" sx={styles.tabStyles} />
                </Tabs>


                {(!this.context.isOnline)
                    && (
                        <Box style={styles.offlineBanner}>
                            <Typography style={styles.offlineBannerText}>
                                You're currently offline but you can still add tasks and send messages.
                            </Typography>

                        </Box>
                    )}

               
                <Grid container style={styles.gridContainer}>
            
                {/* {!this.context.isOnline && (
                        <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom:'10px'
                        }}
                      >
                        <CloudOffIcon sx={{ color: '#F57C00', marginRight: '8px' }} />
                        <Typography sx={{ ...styles.subtitle, color: '#F57C00', fontWeight: 600 }}>
                        Oops! It looks like you're offline
                        </Typography>
                      </Box>
                )} */}
                    <Grid item xs={12} style={styles.gridItem}>
                        
                        <Typography style={styles.tasksTitle}>Tasks</Typography>

                        <Button style={styles.iconButtonStyles} onClick={this.toggleSearchBox}>
                            {!showSearchBox ? (
                                <SearchIcon style={styles.iconColor} />
                            ) : (
                                <CloseIcon style={styles.iconColor} />
                            )}
                        </Button>

                        {/* to be added when doing filters */}
                        {/* <Button style={styles.iconButtonStyles} onClick={this.openCategoryDialog}>
                            <FilterListIcon style={styles.iconColor} />
                        </Button> */}

                        <Button style={styles.addButtonStyles} onClick={() => this.props.newTask()}>
                            <AddTaskIcon />
                        </Button>
                    </Grid>
                </Grid>

                {selectedCategory !== 'All Categories' && (
                    <Box sx={{ padding: '0 12px', marginTop: 1 }}>
                        <Chip
                            size="medium"
                            label={selectedCategory}
                            onDelete={() => this.handleCategoryChange('All Categories')}
                            sx={{ marginTop: 1 }}
                        />
                    </Box>
                )}

                {showSearchBox && (
                    <Box sx={{ padding: '0 12px' }}>
                        <TextField
                            fullWidth
                            style={styles.searchBox}
                            variant="outlined"
                            placeholder="Search for..."
                            value={searchQuery}
                            onChange={this.handleSearchChange}
                            sx={styles.textFieldSx}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon style={styles.searchIconStyle} onClick={null} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                )}

                {this.context.showTaskAddedSuccess && (
                    <div style={{ padding: '12px 16px 0px 16px ' }}>
                        <Box sx={styles.successMessageBox}>
                            <Box sx={styles.successMessageInnerBox}>
                                <CheckCircleIcon sx={styles.successMessageIcon} />
                                <Typography sx={styles.successMessageText}>Task added successfully</Typography>
                            </Box>
                        </Box>
                    </div>
                )}

                {selectedTab === 0 && (
                    // Render Active tab content
                    <div>{this.renderAllActiveTasks(filteredTasks)}</div>

                    // <div>{this.renderUserView(filteredTasks)}</div>
                )}

                {/* {selectedTab === 1 && (
                    // Render Pending tab content
                    <div>{this.renderPendingTasks()}</div>
                )} */}

                {selectedTab === 1 && (
                    // Render Completed tab content
                    <div>{this.renderCompletedTasks()}</div>
                )}
            </div>
        );
    }
}

export default TaskPage;

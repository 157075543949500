
import Dexie from 'dexie';

const db = new Dexie('AppDataDB');

db.version(1).stores({

  messages: 'ID, task_id, admin_timestamp_read, family_id, file_metadata, message, referencedMessage, secret, timestamp, type, uploaded_file, user_id',
  pendingTasks: '++id, requestPayload'

});


// Add a pending task to IndexedDB.
export async function addPendingTask(task) {
  return db.pendingTasks.add(task);
}

// Retrieve all pending tasks.
export async function getPendingTasks() {
  return db.pendingTasks.toArray();
}

// Delete a pending task by its auto-incremented ID.
export async function deletePendingTask(id) {
  return db.pendingTasks.delete(id);
}

// Optionally, clear all pending tasks.
export async function clearAllPendingTasks() {
  return db.pendingTasks.clear();
}

export default db;
